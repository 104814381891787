import TextInput, {
    TextInputProps,
} from '@nkc-frontend/cat-design/dist/components/TextInput';
import { Form } from 'antd';
import { FC } from 'react';

import classes from './UserInfoForm.module.scss';
import { AddButton } from 'src/applications/base/app/react-app/components/common/admin/FormControls/AddButton';
import { RemoveButton } from 'src/applications/base/app/react-app/components/common/admin/FormControls/RemoveButton';

export interface UserWorkInfoFormProps {
    className?: string;
}

const FormTextField: FC<Partial<TextInputProps>> = TextInput as any;

export const UserWorkInfoForm: FC<UserWorkInfoFormProps> = (props) => {
    const { className } = props;

    return (
        <div className={className}>
            <Form.List name={'workingPhones'} initialValue={['']}>
                {(fields, { add, remove }) =>
                    fields.map((field, idx) => (
                        <Form.Item key={field.key} className={classes.field}>
                            <Form.Item {...field} noStyle initialValue={''}>
                                <FormTextField label={`Рабочий телефон`} />
                            </Form.Item>
                            {idx === 0 && <AddButton add={add} />}
                            {idx !== 0 && (
                                <RemoveButton remove={remove} idx={idx} />
                            )}
                        </Form.Item>
                    ))
                }
            </Form.List>
            <Form.Item name={'workingAddress'} className={classes.field}>
                <FormTextField label={'Рабочее место'} />
            </Form.Item>
            <Form.Item
                name={'roomNumber'}
                className={classes.field}
                rules={[
                    { type: 'number', message: 'Кабинет должен быть числом' },
                ]}
                normalize={(val) => {
                    const num = Number(val);
                    return isNaN(num) ? val : num;
                }}
            >
                <FormTextField label={'Номер кабинета'} />
            </Form.Item>
        </div>
    );
};
