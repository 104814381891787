import 'src/applications/base/stylesheets/user-profile-index.scss';

import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import urlJoin from 'url-join';
import { useStores } from 'src/utils/mobx-store-utils/useStores';
import { ProfileSlice } from 'src/applications/base/types/stores';
import { useComputed } from 'src/applications/base/app/react-app/hooks/useComputed';
import { toJS } from 'mobx';
import { Form } from 'antd';
import { Button } from '@nkc-frontend/cat-design';
import { UserProfileFormFields } from 'src/applications/base/types/profiles';
import { UserInfoPanel } from 'src/applications/base/app/react-app/pages/profile/pages/user-profile/UserInfoPanel';

import classes from './UserProfile.module.scss';
import commonClasses from 'src/applications/base/stylesheets/forms.module.scss';
import { UserPersonalInfoForm } from '../UserInfoForm/UserPersonalInfoForm';
import { antdSidepageFormLayout } from 'src/applications/base/app/utils/constants';
import { UserWorkInfoForm } from 'src/applications/base/app/react-app/pages/profile/pages/user-profile/UserInfoForm/UserWorkInfoForm';
import { useCompareFields } from 'src/applications/base/app/react-app/hooks/useCompareFields';
import { hasErrors } from 'src/applications/base/app/utils/form';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

export interface UserProfileProps {
    className?: string;
}

export const UserProfile: FC<UserProfileProps> = observer(() => {
    const { profiles } = useStores<ProfileSlice>();

    const [form] = Form.useForm<UserProfileFormFields>();

    const profile = useComputed(() => toJS(profiles.getProfile()));

    const areFieldsEqual = useCompareFields(profile);

    useEffect(() => {
        if (profile) {
            //удаляем null и undefined поля, чтобы на форме не перезаписались
            //поля Form.List с initialValue ['']
            const formFields = omitBy(
                {
                    email: profile.email,
                    personalPhones: profile.personalPhones,
                    workingPhones: profile.workingPhones,
                    workingAddress: profile.workingAddress,
                    extraEmails: profile.extraEmails,
                    roomNumber: profile.roomNumber,
                },
                (val) => isNil(val) || (Array.isArray(val) && val.length === 0)
            );

            form.setFieldsValue(formFields);
        }
    }, [profile, form]);

    const onFinish = (values: UserProfileFormFields) => {
        const roomNumber = Number(values.roomNumber);

        profiles.editCurrentUserProfile({
            email: values.email?.trim() ?? '',
            fullName: profile?.fullName ?? '',
            extraEmails: Array.isArray(values.extraEmails)
                ? values.extraEmails.filter(Boolean)
                : [],
            personalPhones: Array.isArray(values.personalPhones)
                ? values.personalPhones.filter(Boolean)
                : [],
            workingPhones: Array.isArray(values.workingPhones)
                ? values.workingPhones.filter(Boolean)
                : [],
            workingAddress: values.workingAddress?.trim() ?? '',
            roomNumber: isNaN(roomNumber) ? undefined : roomNumber,
        });
    };

    const authConfigLS: any = localStorage.getItem('authConfig');
    const CHANGE_PASSWORD_URL = urlJoin(
        JSON.parse(authConfigLS).url as string,
        'realms',
        JSON.parse(authConfigLS).realm as string,
        'account',
        'password'
    );

    return (
        <div>
            <Form
                className={commonClasses.form}
                form={form}
                onFinish={onFinish}
                {...antdSidepageFormLayout}
            >
                <div className={classes.panels}>
                    <UserInfoPanel
                        className={classes.panel}
                        title={'Персональные данные'}
                    >
                        <UserPersonalInfoForm />
                    </UserInfoPanel>
                    <UserInfoPanel
                        className={classes.panel}
                        title={'Данные должности'}
                    >
                        <UserWorkInfoForm />
                    </UserInfoPanel>
                </div>
                <div className={classes.password}>
                    <a href={CHANGE_PASSWORD_URL} rel={'noreferrer'}>
                        Изменить пароль
                    </a>
                </div>
                <Form.Item shouldUpdate className={classes.save}>
                    {() => {
                        const shouldShowSaveBtn =
                            profile && !areFieldsEqual(form.getFieldsValue());
                        return (
                            shouldShowSaveBtn && (
                                <Button
                                    type={'submit'}
                                    disabled={hasErrors(form.getFieldsError())}
                                >
                                    Сохранить изменения
                                </Button>
                            )
                        );
                    }}
                </Form.Item>
            </Form>
        </div>
    );
});
