import { FC } from 'react';

import classes from './UserInfoPanel.module.scss';

export interface UserInfoPanelProps {
    className?: string;
    title: string;
}

export const UserInfoPanel: FC<UserInfoPanelProps> = (props) => {
    const { className, children, title } = props;

    return (
        <section className={className}>
            <h2 className={classes.header}>{title}</h2>
            <div className={classes.divider} />
            <div className={classes.children}>{children}</div>
        </section>
    );
};
