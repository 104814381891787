import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';

type Comparator<TSource, TFields> = (
    fieldName: keyof TFields,
    fields: TFields,
    source: TSource
) => boolean;

const defaultComparator: Comparator<any, any> = (fieldName, fields, source) =>
    isEqual(fields[fieldName], source[fieldName]);

/**
 * Сравнивает поля объекта с соответствующими полями объекта source и возвращает true при равенстве.
 * Полезно при сравнении полей в форме с изначальными значениями.
 * В сравнении участвуют только те поля, которые есть в fields.
 * @param source - объект, с которым сравниваются поля
 * @example
 * const comparator = getCompareFields({foo: 'foo', bar: 'bar'});
 * comparator({foo: 'foo'});//true
 */
export const getCompareFields = <TSource extends Record<string, any>>(
    source: TSource | undefined | null
) => {
    /**
     * @param fields - объект, поля которого сравниваются с source
     * @param comparator - функция сравнения
     */
    return <TFields extends Record<string, any>>(
        fields: TFields,
        comparator: Comparator<TSource, TFields> = defaultComparator
    ) => {
        if (Object.is(source, fields)) {
            return true;
        }
        if (!source) {
            return false;
        }

        const fieldNames = Object.keys(fields);

        const allFieldsEqual = fieldNames.every((fName) =>
            comparator(fName, fields, source)
        );

        return allFieldsEqual;
    };
};

/**
 * хук-обёртка над getCompareFields
 */
export const useCompareFields = <TSource extends Record<string, any>>(
    source: TSource | undefined | null
) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const comparatorFunction = useCallback(getCompareFields(source), [source]);

    return comparatorFunction;
};
