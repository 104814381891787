import { FC } from 'react';
import { FontAwesomeIcons } from 'src/applications/base/app/utils/constants';
import { Button } from '@nkc-frontend/cat-design';
import commonClasses from 'src/applications/base/stylesheets/forms.module.scss';

export interface AddButtonProps {
    add(): void;
}

export const AddButton: FC<AddButtonProps> = ({ add }) => (
    <Button
        className={commonClasses['form__add-btn']}
        visualStyle={'plain'}
        after={<i className={FontAwesomeIcons.PLUS} />}
        onClick={() => add()}
    />
);
