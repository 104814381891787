import { FC } from 'react';
import { FontAwesomeIcons } from 'src/applications/base/app/utils/constants';
import { Button } from '@nkc-frontend/cat-design';
import commonClasses from 'src/applications/base/stylesheets/forms.module.scss';

export interface AddButtonProps {
    idx: number;
    remove(idx: number): void;
}

export const RemoveButton: FC<AddButtonProps> = ({ remove, idx }) => (
    <Button
        className={commonClasses['form__rm-btn']}
        visualStyle={'plain'}
        after={<i className={FontAwesomeIcons.TRASH} />}
        onClick={() => remove(idx)}
    />
);
