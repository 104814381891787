import TextInput, {
    TextInputProps,
} from '@nkc-frontend/cat-design/dist/components/TextInput';
import { Form } from 'antd';
import { FC } from 'react';

import classes from './UserInfoForm.module.scss';
import { AddButton } from 'src/applications/base/app/react-app/components/common/admin/FormControls/AddButton';
import { RemoveButton } from 'src/applications/base/app/react-app/components/common/admin/FormControls/RemoveButton';

export interface UserPersonalInfoFormProps {
    className?: string;
}

const FormTextField: FC<Partial<TextInputProps>> = TextInput as any;

export const UserPersonalInfoForm: FC<UserPersonalInfoFormProps> = (props) => {
    const { className } = props;

    return (
        <div className={className}>
            <Form.Item
                name={'email'}
                className={classes.field}
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        type: 'email',
                        message: 'Значение не соответствует формату e-mail',
                    },
                ]}
            >
                <FormTextField label={'Email'} />
            </Form.Item>
            <Form.List name={'extraEmails'} initialValue={['']}>
                {(fields, { add, remove }) =>
                    fields.map((field, idx) => (
                        <Form.Item key={field.key} className={classes.field}>
                            <Form.Item
                                {...field}
                                noStyle
                                initialValue={''}
                                rules={[
                                    {
                                        type: 'email',
                                        message:
                                            'Значение не соответствует формату e-mail',
                                    },
                                ]}
                            >
                                <FormTextField
                                    label={`E-mail (дополнительный)`}
                                />
                            </Form.Item>
                            {idx === 0 && <AddButton add={add} />}
                            {idx !== 0 && (
                                <RemoveButton remove={remove} idx={idx} />
                            )}
                        </Form.Item>
                    ))
                }
            </Form.List>
            <Form.List name={'personalPhones'} initialValue={['']}>
                {(fields, { add, remove }) =>
                    fields.map((field, idx) => (
                        <Form.Item key={field.key} className={classes.field}>
                            <Form.Item {...field} noStyle initialValue={''}>
                                <FormTextField label={`Мобильный телефон`} />
                            </Form.Item>
                            {idx === 0 && <AddButton add={add} />}
                            {idx !== 0 && (
                                <RemoveButton remove={remove} idx={idx} />
                            )}
                        </Form.Item>
                    ))
                }
            </Form.List>
        </div>
    );
};
